export const environment = {
	production: false,
	apiServer: 'https://api.qa.tk.videolink.app',
	adminUrl: 'https://admin.qa.tk.videolink.app',
	agentUrl: 'https://agent.qa.tk.videolink.app',
	easyUrl: 'https://easy.qa.videolink.app/conditions',
	wsServer: '', //Desactivado a la fecha
	storage: 'https://cdn.qa.tk.videolink.app',
	vlTokenSupport: '8b856fee2b4bdaa534c869092584cd4564a1a59c123b9a20a96b51178d132729',
	urlCdnContent: 'https://content.qa.tk.videolink.app/autoload.js',
	cdnFiles: 'https://content.qa.tk.videolink.app',
	cdnWidget: 'https://widget.qa.tk.videolink.app',
	mode: 'qa',
};
