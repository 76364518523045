import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@services/global/auth.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WithoutTokenGuard  {
	constructor(private _authSrc: AuthService, private _router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.validateSession();
	}
	canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.validateSession();
	}

	validateSession(): boolean {
		try {
			const userInfo = this._authSrc.getInfo();
			if (userInfo) throw {};
			return true;
		} catch (error) {
			this._router.navigate(['/tk']);
			return false;
		}
	}
}
