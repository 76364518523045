import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlFormGroupS1Component } from './vl-form-group-s1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { VlSelectCustomModule } from '@components/vl-select-custom/vl-select-custom.module';
import { VlRangeModule } from '@components/vl-range/vl-range.module';
import { FormInputTrimModule } from '@directives/form-input-trim/form-input-trim.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VlIconModule } from '@components/vl-icon/vl-icon.module';
import { MatNativeDateModule } from '@angular/material/core';
import { VlDatalistModule } from '@components/vl-datalist/vl-datalist.module';
import { VlInputUploadImgModule } from '@components/vl-input-upload-img/vl-input-upload-img.module';

@NgModule({
	declarations: [VlFormGroupS1Component],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		VlSelectCustomModule,
		VlRangeModule,
		FormInputTrimModule,
		MatDatepickerModule,
		MatNativeDateModule,
		VlIconModule,
		FormInputTrimModule,
		VlDatalistModule,
		VlInputUploadImgModule,
	],
	exports: [VlFormGroupS1Component, FormsModule, ReactiveFormsModule, MatIconModule, VlIconModule, FormInputTrimModule],
	providers: [MatDatepickerModule],
})
export class VlFormGroupS1Module {}
