import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AuthService } from '@services/global/auth.service';
import { Router } from '@angular/router';
import { LoginService } from '@services/global/login.service';
import { NotificationService } from '@services/global/notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private _authSrc: AuthService,
		private _router: Router,
		private _loginSrc: LoginService,
		private notificationSrc: NotificationService
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const headers: { [key: string]: string } = {};
		this.setBearerToken(headers);
		this.setContentType(headers, request);

		const req = request.clone({ setHeaders: headers });

		return next.handle(req).pipe(
			tap((res) => {
				if (res instanceof HttpResponse && (res.body?.token || res.body?.data?.token)) {
					this.validateBodyToken(res);
				}
			}),
			catchError((error: HttpErrorResponse) => {
				let errorToSend: HttpErrorResponse | Object = error;

				if (error?.error?.target?.status === 0) error.error.code = 'RED';

				this.errorHandlerWithNotification(error);

				const actionsCustom: any = {
					401: () => {
						errorToSend = {};
						this.notificationSrc.show({ type: 'error', title: '¡Sesión finalizada!', msg: 'Su sesión ha expirado' });
						this._authSrc.clearAll();
						this._router.navigate(['/auth/login']);
					},
					426: () => {
						this._loginSrc.refreshToken();
						errorToSend = {};
					},
				};

				actionsCustom[error.status] && actionsCustom[error.status]();

				return throwError(() => errorToSend);
			})
		);
	}

	setBearerToken(headers: { [key: string]: string }): void {
		const token = this._authSrc.getToken();
		if (!token) return;
		headers['Authorization'] = `videolink ${token}`;
	}

	setContentType(headers: { [key: string]: string }, request: HttpRequest<unknown>): void {
		const isFile = request.headers.has('file');
		if (isFile) return;
		headers['Content-Type'] = `application/json`;
	}

	validateBodyToken(res: HttpResponse<TokenResponse | CustomResponse<TokenResponse>>) {
		const token = (res.body as TokenResponse).token || (res.body as CustomResponse<TokenResponse>).data.token;
		this._authSrc.setToken(token);
	}

	errorHandlerWithNotification(error: HttpErrorResponse) {
		const acceptedCodes: { [key: string]: { title?: string; msg?: string } } = {
			RED: {
				title: 'Error en red',
				msg: 'Verifique el estado de su internet.',
			},
			CREDENCIALS_INVALID: {
				title: 'Credenciales inválidas.',
				msg: 'Por favor, verifique sus credenciales e intente nuevamente.',
			},
			USER_INACTIVE: {
				title: 'Usuario no activo.',
				msg: 'Por favor, póngase en contacto con un administrador.',
			},
			USER_NOT_PRIVILEGES_ADM: {
				title: 'Privilegios insuficientes.',
				msg: 'Usted no cuenta con los permisos suficientes para iniciar sesión en el portal del administrador.',
			},
			DUPLICATED_SESSION_ADM: {
				title: 'Sesión duplicada',
				msg: 'Usted posee ya otra sesión activa en otro administrador.',
			},
			TOKEN_INVALID: {
				title: 'Token no válido.',
				msg: 'El token que usted utilizo no es válido.',
			},
			TOKEN_EXPIRED: {
				title: 'Token expirado.',
				msg: 'El token que usted trata de utilizar a expirado.',
			},
			TOKEN_USED: {
				title: 'Token utilizado.',
				msg: 'El token que usted trata de utilizar ya fue utilizado.',
			},
			TOKEN_DISABLED: {
				title: 'Token no activo.',
				msg: 'El token que usted trata de utilizar no se encuentra activo.',
			},
			ABILITY_HAS_SUBABILITIES: {
				title: 'Tipo de atención no eliminada.',
				msg: 'Existen sub-atenciones que dependen de este tipo de atención.',
			},
			GROUP_HAS_APPS: {
				title: 'Grupo no eliminado.',
				msg: 'Existen aplicaciones que dependen de este grupo.',
			},
			GROUPS_ONLY_EMBE: {
				title: 'Grupos solo para embebidos.',
				msg: 'Todos los grupos seleccionados estan configurado para solo embebidos.',
			},
			ROL_HAS_USERS: {
				title: 'Rol no eliminado.',
				msg: 'Existen usuarios que dependen de este rol.',
			},

			TICKETS_DEPEND_USER: {
				title: 'Usuario no eliminado.',
				msg: 'Existe al menos un ticket que depende de este usuario.',
			},
			TICKETS_DEPEND_GROUP: {
				title: 'Grupo no eliminado.',
				msg: 'Existe al menos un ticket que depende de este grupo.',
			},
			TICKETS_DEPEND_ABILITY: {
				title: 'Tipo de atención no eliminado.',
				msg: 'Existe al menos un ticket que depende de este tipo de atención.',
			},
			TICKETS_DEPEND_SUBABILITY: {
				title: 'Sub-atención no eliminada.',
				msg: 'Existe al menos un ticket que depende de esta sub-atención.',
			},
			TICKETS_DEPEND_STATUS: {
				title: 'Estado del ticket no eliminado.',
				msg: 'Existe al menos un ticket que depende de este estado del ticket.',
			},
			INACTIVE_CREDENCIALS_INVALID: {
				title: 'Usuario desactivado.',
				msg: 'Has superado el intento máximo de inicio de sesión, póngase en contacto con un administrador.',
			},
			BLOCK_CREDENCIALS_INVALID: {
				title: 'Usuario bloqueado',
				msg: 'Por favor, póngase en contacto con un administrador.',
			},
			// USERS_DEPEND_GROUP: {
			// 	title: 'No se puede eliminar el grupo.',
			// 	msg: 'Hay usuarios que dependen de este grupo.',
			// },
			// USERS_DEPEND_GROUP_ABILITIES: {
			// 	title: 'No se puede actualizar el grupo.',
			// 	msg: 'Hay usuarios que dependen de algún tipo de atención que estaba seleccionado.',
			// },
			// USERS_DEPEND_ATTENTION_TYPE: {
			// 	title: 'No se puede eliminar el tipo de atención.',
			// 	msg: 'Hay usuarios que dependen de este tipo de atención.',
			// },

			// USERS_DEPEND_SUB_ATTENTION_TYPE: {
			// 	title: 'No se puede eliminar la sub-atención.',
			// 	msg: 'Hay usuarios que dependen de esta sub-atención.',
			// },
			// USERS_DEPEND_PARENT_SUB_ATTENTION_TYPE: {
			// 	title: 'No se puede actualizar la sub-atención.',
			// 	msg: 'Hay usuarios que dependen del tipo de atención que estaba seleccionado en esta sub-atención.',
			// },

			// Alerts: Service validation backend
			//UPDATE GROUPS
			ALL_GROUPS_ONLY_EMBEDED: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'Todos los grupos no pueden ser de solo embebidos.',
			},
			USER_DEPEND_ONLY_THIS_GROUP: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'Existe al menos un usuario que solo depende de este grupo. Por lo que no puede ser solo embebido.',
			},
			USER_DEPEND_THIS_GROUP_WITCHOUT_ONLY_EMBEDED: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'No pueden existir usuarios unicamente con grupos de solo embebido.',
			},
			ABILITY_DEPEND_GROUP: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'Existe al menos un tipo de atención que depende solo de este grupo.',
			},
			USERS_DEPEND_GROUP_ABILITIES: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'Existe al menos un usuario que depende de que el tipo de atención forme parte de este grupo.',
			},
			APP_DEPEND_GROUP_ACTIVE: {
				title: 'Error al intentar actualizar este recurso',
				msg: 'Existe al menos una aplicación que depende de que este grupo se encuentre activo.',
			},

			//DELETE GROUPS
			USERS_WITH_THIS_GROUP: {
				title: 'Error al intentar eliminar este recurso',
				msg: 'Existe al menos un usuario que depende de este grupo.',
			},
			APP_WITH_THIS_GROUP: {
				title: 'Error al intentar eliminar este recurso',
				msg: 'Existe al menos una aplicación que depende de este grupo.',
			},
			ABILITY_ONLY_WITH_THIS_GROUP: {
				title: 'Error al intentar eliminar este recurso',
				msg: 'Existe al menos un tipo de atención que depende de este grupo.',
			},

			// UPDATE ATTENTION TYPES
			USERS_DEPEND_ATTENTION_TYPE_STATUS: {
				title: 'Error al intentar actualizar este recurso.',
				msg: 'No se puede desactivar el tipo de atención. Hay usuarios que dependen de este tipo de atención.',
			},
			USERS_DEPEND_GROUP_OF_ATTENTION_TYPE: {
				title: 'Error al intentar actualizar este recurso.',
				msg: 'Existe al menos un usuario que depende de que el grupo forme parte de este tipo de atención.',
			},
			GROUPS_DEPEND_ATTENTION_TYPE: {
				title: 'Error al intentar actualizar este recurso.',
				msg: 'Existe al menos un grupo que depende de este tipo de atención.',
			},

			// UPDATE SUB ATTENTION TYPES
			USERS_DEPEND_PARENT_SUB_ATTENTION_TYPE: {
				title: 'Error al intentar actualizar este recurso.',
				msg: 'Hay usuarios que dependen del tipo de atención que estaba seleccionado en esta sub-atención.',
			},
			USERS_DEPEND_SUB_ATTENTION_TYPE_STATUS: {
				title: 'Error al intentar actualizar este recurso.',
				msg: 'No se puede desactivar la sub-atención. Hay usuarios que dependen de esta sub-atención.',
			},

			// DELETE SUB ATTENTION TYPES
			USERS_DEPEND_SUB_ATTENTION_TYPE: {
				title: 'Error al intentar eliminar este recurso.',
				msg: 'Existe al menos un usuario que depende de esta sub-atención.',
			},
		};

		if (!acceptedCodes[error.error.code]) return;

		this.notificationSrc.show({ type: 'error', ...acceptedCodes[error.error.code] });
	}
}
