import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@services/global/auth.service';
import { NotificationService } from '@services/global/notification.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WithTokenGuard  {
	constructor(private _authSrc: AuthService, private _router: Router, private notificationSrc: NotificationService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.validateSession();
	}
	canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.validateSession();
	}

	validateSession(): boolean {
		try {
			const userInfo = this._authSrc.getInfo();
			if (!userInfo || !userInfo.exp) throw {};

			const isExpired = new Date(0);
			isExpired.setUTCSeconds(userInfo.exp);

			if (new Date() > isExpired) throw { code: 412 };

			if (!this._authSrc.havePermission('co.access.admin')) throw { code: 401 };

			return true;
		} catch (error: any) {
			if (error?.code === 412) this.notificationSrc.show({ type: 'error', title: '¡Sesión finalizada!', msg: 'Su sesión ha expirado' });
			if (error?.code === 401)
				this.notificationSrc.show({
					type: 'error',
					title: 'Privilegios insuficientes!',
					msg: 'Usted no cuenta con los permisos suficientes para iniciar sesión en el portal del administrador',
				});
			this._authSrc.clearAll();
			this._router.navigate(['/auth']);
			return false;
		}
	}
}
