import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WithTokenGuard } from '@guards/with-token.guard';
import { WithoutTokenGuard } from '@guards/without-token.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		canActivate: [WithoutTokenGuard],
		canLoad: [WithoutTokenGuard],
	},
	{
		path: '',
		loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
		canActivate: [WithTokenGuard],
		canLoad: [WithTokenGuard],
	},
	{ path: '**', redirectTo: 'auth' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
