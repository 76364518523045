import { FormControl, FormGroup } from '@angular/forms';

export const getErrorFormMsg = ({
	formRef = null,
	formControl = null,
	keyForm = '',
	minlength = 0,
	maxlength = 0,
	minCharLower = 0,
	minCharUpper = 0,
	minAmountNum = 0,
	fieldEqual = '',
	min = 0,
	max = 0,
	textRequired = 'Este campo es requerido',
}: {
	formRef: FormGroup | null;
	formControl?: FormControl | null;
	keyForm: string;
	minlength?: number;
	maxlength?: number;
	minCharLower?: number;
	minCharUpper?: number;
	minAmountNum?: number;
	fieldEqual?: string;
	min?: number;
	max?: number;
	textRequired?: string;
}): null | string => {
	if (!formRef) return null;

	let errorMsg: { [key: string]: string } = {
		required: textRequired,
		min: `Este campo puede tener como mínimo el valor ${(formRef as any).controls[keyForm]?.getError('min')?.min ?? min}`,
		max: `Este campo puede tener como máximo el valor ${(formRef as any).controls[keyForm]?.getError('max')?.max ?? max}`,
		minlength: `Este campo debe tener una longitud mínima de ${
			(formRef as any).controls[keyForm]?.getError('minlength')?.requiredLength ?? minlength
		} caracteres`,
		maxlength: `Este campo debe tener una longitud máxima de ${
			(formRef as any).controls[keyForm]?.getError('maxlength')?.requiredLength ?? maxlength
		} caracteres`,
		pattern: 'Este campo posee un formato no válido',
		hasMinTheseCharInLowerCase: `Este campo al menos debe de tener ${minCharLower} minúscula(s)`,
		hasMinTheseCharInUpperCase: `Este campo al menos debe de tener ${minCharUpper} mayúscula(s)`,
		hasMinThisAmountOfNumbers: `Este campo al menos debe de tener ${minAmountNum} numero(s)`,
		isEqual: `Este campo debe ser identico al campo ${fieldEqual}`,
		hasSpecialChar: `Este campo debe tener caracteres especiales`,
		hasGlobalPermissions: `Los roles deben de tener al menos un permiso de acceso global`,
		uniqueEmail: `El correo indicado ya se encuentra registrado`,
	};

	let errorTrigger = Object.keys(formRef.get(keyForm)?.errors || {});

	return errorTrigger.length > 0 && (formRef.get(keyForm)?.dirty || formRef.get(keyForm)?.touched) ? errorMsg[errorTrigger[0]] : null;
};
